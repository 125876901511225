import React, { useEffect, useRef, useState } from 'react';
import Layouts from '../layouts/Layouts';
import { getAuthorizationHeader, http } from '../config/http';
import SpinnerGetData from '../components/common/SpinnerGetData';
import { IconCalendar, IconPrinter, IconTrash } from '@tabler/icons-react';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LayoutsKasir from '../layouts/LayoutsKasir';
import LaporanCashflowKasir from './Kasir/LaporanCashflowKasir';
import { DinamicTitle } from '../utils/DinamicTitle';
import CustomLaporanCashflow from './CustomLaporanCashflow';

const LaporanCashflow = () => {
  DinamicTitle('Laporan Harian');
  const navigate = useNavigate();
  const [tglFilter, setTglFilter] = useState(null);
  const [laporanHarian, setLaporanHarian] = useState([]);
  const [trxMbanking, setTrxMbanking] = useState(0);
  const [pengeluaran, setPengeluaran] = useState(0);
  const [pemasukan, setPemasukan] = useState(0);
  const [trxLuar, setTrxLuar] = useState([]);
  const [loadingLaporanHarian, setLoadingLaporanHarian] = useState(false);
  var jumlah = 0;
  const getPengeluaranHarian = async () => {
    setLoadingLaporanHarian(true);
    await http
      .get(`laporan_harian${tglFilter == null ? '' : `?tanggal=${tglFilter}`}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log("res.data.data : ", res.data.data)
        setLaporanHarian(res.data.data);
        setTrxLuar(res.data.data.cashflow);
        setTrxMbanking(res.data.data.summary.total_m_banking);
        setPengeluaran(res.data.data.summary.total_pengeluaran);
        // console.log(res.data.data.summary)
        setPemasukan(res.data.data.summary.total_pemasukan);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response?.status === 403) {
          navigate('/login');
          // console.log("kemali ke halaman login")
        }
      });
    setLoadingLaporanHarian(false);
  };

  useEffect(() => {
    getPengeluaranHarian();
  }, [tglFilter]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // console.log('tglFilter :', tglFilter);
  function handleSelect(date) {
    // console.log(`date`, date)
    const tgl = moment(date).format('DD-MM-YYYY');

    setTglFilter(tgl);
  }

  function roundNumber(number) {
    return number?.toFixed(3);
  }

  const [barangTerkini, setBarangTerkini] = useState([]);
  const [totalBeratBarangTerkini, setTotalBeratBarangTerkini] = useState(0);
  const [totalHargaBarangTerkini, setTotalHargaBarangTerkini] = useState(0);
  const [totalBijiBarangTerkini, setTotalBijiBarangTerkini] = useState(0);
  const [loadingBarangTerkini, setLoadingBarangTerkini] = useState(false);

  const [customBerat, setCustomBerat] = useState('');
  const [customNama, setCustomNama] = useState('');
  const [customArray, setCustomArray] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDelete = (indexToDelete) => {
    const newArray = customArray.filter((_, index) => index !== indexToDelete);
    setCustomArray(newArray);
  };

  const dropdown = useRef(null);
  const trigger = useRef(null);

  function submitCustom(e) {
    if (customBerat !== '' && customNama !== '') {
      const newItem = { berat: customBerat, nama: customNama };
      setCustomArray((prevArray) => [...prevArray, newItem]);
      setCustomBerat('');
      setCustomNama('');
    } else {
      console.log('Pastikan nilai customBerat dan customNama tidak kosong.');
    }
  }
  // const totalBerat = customArray.reduce((total, item) => total + parseFloat(item.berat), 0).toFixed(3);
  const totalBerat = parseFloat(customArray.reduce((total, item) => total + parseFloat(item.berat), 0)).toFixed(3);
  const hasil = parseFloat(totalBeratBarangTerkini).toFixed(3) + totalBerat;

  // console.log('hasil : ', totalBerat, 'data : ', hasil);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  async function getBarangTerkini() {
    const id_brankas = localStorage.getItem('id_brankas');

    setLoadingBarangTerkini(true);
    await http
      .get(`summary/item_terkini?cabang=${id_brankas}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setTotalBijiBarangTerkini(res.data.total_item);
        setTotalBeratBarangTerkini(res.data.total_berat);
        // setTotalBeratBarangTerkini('2.300');
        setTotalHargaBarangTerkini(res.data.total_modal);
        setBarangTerkini(res.data.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingBarangTerkini(false);
  }
  useEffect(() => {
    getBarangTerkini();
  }, []);

  const role = localStorage.getItem('role');

  if (role == 'kasir') {
    return (
      <LayoutsKasir>
        <LaporanCashflowKasir
          laporanHarian={laporanHarian}
          loadingLaporanHarian={loadingLaporanHarian}
          numberWithCommas={numberWithCommas}
          roundNumber={roundNumber}
          trxLuar={trxLuar}
          tglFilter={tglFilter}
          handleSelect={handleSelect}
          // barangTerkini={barangTerkini}
          // loadingBarangTerkini={loadingBarangTerkini}
          // totalBeratBarangTerkini={totalBeratBarangTerkini}
          // totalBijiBarangTerkini={totalBijiBarangTerkini}
          // totalHargaBarangTerkini={totalHargaBarangTerkini}
        />
      </LayoutsKasir>
    );
  } else {
    return (
      <Layouts title={'Laporan'}>
        <div className="sm:mx-8 mx-2 p-2 mt-2 ">
          <div className="bg-white p-5 ">
            <div className="mb-6 flex justify-between items-center">
              <div className="font-normal text-lg">
                Tanggal {laporanHarian.tanggal} {!tglFilter == null && tglFilter}
              </div>
              <div className='gap-2 flex'>
                <div className="dropdown dropdown-end">
                  <label
                    tabIndex={0}
                    className="bg-orange-500 p-2  flex items-center text-white hover:bg-orange-600  cursor-pointer"
                  >
                    <IconCalendar />
                  </label>
                  <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box -left-72 w-52">
                    <Calendar date={new Date()} onChange={handleSelect} />
                  </div>
                </div>
                {/* <button className='px-2 py-2 text-white bg-blue-500 hover:bg-blue-600'>
                  <IconPrinter />
                </button> */}
              </div>
            </div>
            {loadingLaporanHarian ? (
              <SpinnerGetData />
            ) : (
              <>
                {laporanHarian == undefined ? (
                  <div></div>
                ) : (
                  <>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-bold p-4 text-2xl">KAS</div>
                  <div className="border border-black col-span-2 p-4">
                    <span className="text-2xl font-bold"> Rp. {numberWithCommas(laporanHarian.kas)}</span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">PEMBELIAN</div>
                  <div className="border border-black col-span-2 p-4">
                    {laporanHarian.pembelian?.total_harga == 0 ? (
                      <div>Data Kosong</div>
                    ) : (
                      <>
                        <div className="font-normal text-lg">
                          {' '}
                          Rp. {numberWithCommas(laporanHarian?.pembelian?.total_harga)} /{' '}
                          {roundNumber(laporanHarian?.pembelian?.total_berat)} gram / Rp.{' '}
                          {numberWithCommas(Math.round(laporanHarian?.pembelian?.rata_rata))}{' '}
                        </div>
                        <div className="mt-4">
                          {laporanHarian.pembelian?.items === undefined ? (
                            ''
                          ) : (
                            <>
                              {laporanHarian.pembelian?.items?.map((itp, i) => {
                                return (
                                  <div key={i} className="mb-2 ">
                                    <span className="text-lg text-red-500">*</span> {itp.karat} karat :{' '}
                                    {itp.total_berat} gram / Rp.
                                    {numberWithCommas(itp.total_harga)} / Rp.
                                    {numberWithCommas(Math.round(itp.rata_rata))}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">PENJUALAN</div>
                  <div className="border border-black col-span-2 p-4">
                    {laporanHarian.penjualan?.total_harga == 0 ? (
                      <div>Data Kosong</div>
                    ) : (
                      <>
                        <div className="font-normal text-lg">
                          {' '}
                          Rp. {numberWithCommas(laporanHarian?.penjualan?.total_harga)} /{' '}
                          {roundNumber(laporanHarian?.penjualan?.total_berat)} gram / Rp.{' '}
                          {/* {laporanHarian?.penjualan?.total_berat} gram / Rp.{' '} */}
                          {numberWithCommas(Math.round(laporanHarian?.penjualan?.rata_rata))}{' '}
                        </div>
                        <div className="mt-4">
                          {laporanHarian.penjualan?.items === undefined ? (
                            ''
                          ) : (
                            <>
                              {laporanHarian.penjualan?.items?.map((itp, i) => {
                                return (
                                  <div key={i} className="mb-2">
                                    <span className="text-lg text-red-500">*</span> {itp.karat} karat : {itp.total_item} biji /{' '}
                                    {itp.total_berat} gram / Rp.
                                    {numberWithCommas(itp.total_harga)} / Rata-rata.{' '}
                                    {numberWithCommas(Math.round(itp.rata_rata, 1))}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-semibold p-4 text-md md:text-lg">
                    LUAR TRANSAKSI
                    <div>Pengeluaran & Pemasukan</div>
                  </div>
                  <div className="border border-black col-span-2 p-4">
                    <div className="mt-2 text-md italic">
                      {trxLuar.map((res, i) => {
                        return (
                          <div key={i} className="mb-2">
                            <span className="text-lg text-red-500">*</span>
                            {res.title} /
                            <span className="font-normal text-lg">
                              Rp.
                              {numberWithCommas(res.nominal)} ({res.tipe})
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="border border-black font-bold p-4 text-2xl">SISA KAS</div>
                  <div className="border border-black col-span-2 p-4">
                    <span className="font-bold text-2xl"> Rp. {numberWithCommas(laporanHarian.sisa_kas)}</span>
                  </div>
                </div>
              </>
                )}
              </>
            )}
          </div>
          <div>
            <div className="bg-white mt-3  p-4 overflow-x-auto">
              <div>
                {loadingBarangTerkini ? (
                  <SpinnerGetData />
                ) : (
                  <>
                    <div className="px-4 pt-2 pb-1 flex items-center justify-between font-bold uppercase text-lg text-slate-900 bg-orange-500/30">
                      <div>Barang Brankas</div>
                      <div>
                        <CustomLaporanCashflow
                          customBerat={customBerat}
                          customNama={customNama}
                          submitCustom={submitCustom}
                          setCustomBerat={setCustomBerat}
                          dropdown={dropdown}
                          dropdownOpen={dropdownOpen}
                          setDropdownOpen={setDropdownOpen}
                          trigger={trigger}
                          setCustomNama={setCustomNama}
                        />
                      </div>
                    </div>
                    <div className="p-1">
                      <table className="w-full border text-sm text-left  overflow-hidden">
                        <thead>
                          <tr className="border text-lg">
                            <th>No</th>
                            <th>Kategori</th>
                            <th>Biji</th>
                            <th>Berat</th>
                            <th>Modal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {barangTerkini.map((bt, index) => (
                            <tr key={index} className="border text-lg">
                              <td>{index + 1}.</td>
                              <td>{bt.kategori}</td>
                              <td>{bt.jumlah_item_terkini}</td>
                              <td>{bt.total_berat_terkini}</td>
                              <td>Rp. {numberWithCommas(bt.total_modal_terkini)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={5}><hr style={{'height':'2px', 'backgroundColor': '#000'}} /></td>
                            {/* <td></td>
                            <td></td>
                            <td></td>
                            <td></td> */}
                          </tr>
                          {/* <tr className="border text-lg">
                            <th></th>
                            <th></th>
                            <th>Jumlah</th>
                            <th>Total Berat</th>
                            <th>Total Modal</th>
                          </tr> */}
                          <tr className="border text-lg">
                              <th></th>
                              <th>Jumlah</th>
                              <th><span className="text-red-600 text-lg font-semibold">{totalBijiBarangTerkini} </span> biji</th>
                              <th><span className="text-red-600 text-lg font-semibold">{totalBeratBarangTerkini} </span> gram</th>
                              <th>Rp.{' '}
                              <span className="text-red-600 text-lg font-semibold">
                                {numberWithCommas(totalHargaBarangTerkini == undefined ? '0' : totalHargaBarangTerkini)}
                              </span>{' '}</th>
                          </tr>
                          <tr>
                            <td colSpan={5}><hr style={{'height':'2px', 'backgroundColor': '#000'}} /></td>
                            {/* <td></td>
                            <td></td>
                            <td></td>
                            <td></td> */}
                          </tr>
                        </tfoot>
                      </table>

                      {/* <div className="mt-10  border  p-2 overflow-x-auto scrollbar-none flex items-center">
                        <div className="whitespace-nowrap  border-black mr-6 font-semibold">
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                          [ <span className="text-red-600">{totalBijiBarangTerkini} </span> biji ] &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                        </div>
                        <div className="whitespace-nowrap mr-6  font-semibold">
                          [ <span className="text-red-600">{totalBeratBarangTerkini} </span> gram ] &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                        </div>
                        <div className="whitespace-nowrap  font-semibold">
                          [{' '}
                          <span className="text-red-600">
                            Rp.
                            {numberWithCommas(totalHargaBarangTerkini == undefined ? '0' : totalHargaBarangTerkini)}
                          </span>{' '}
                          ]
                        </div>
                      </div> */}
                      {customArray.length ?
                        <div className="mt-2">
                        
                          <table className="w-full text-sm text-left overflow-hidden">
                            {/* <thead>
                              <tr className="border">
                                <th>Nama</th>
                                <th>Berat</th>
                              </tr>
                            </thead> */}
                            <tbody>
                              {customArray.map((ca, index) => {
                                jumlah = jumlah + parseFloat(ca.berat);
                                return (<tr key={index} className="border text-lg">
                                    <td></td>
                                    <td></td>
                                    <td style={{'width':'44%'}}>{ca?.nama}</td>
                                    <td style={{'textAlign': 'left', 'float':'left'}}>
                                      <span style={{'textAlign': 'left', 'float':'left'}}>
                                        {ca?.berat} gram
                                      </span>
                                    </td>
                                    <td>
                                      <button onClick={() => handleDelete(index)}>
                                        <IconTrash className="w-5 h-5 text-red-500 stroke-1" />
                                      </button>
                                    </td>
                                  </tr>)
                              })}
                            </tbody>
                            <tfoot>
                                <tr className="border text-lg">
                                    <th></th>
                                    <th></th>
                                    <th>Total</th>
                                    <th><span className="text-red-600 text-lg font-semibold">{parseFloat(totalBeratBarangTerkini) + parseFloat(jumlah)} </span> gram</th>
                                    <th></th>
                                </tr>
                              </tfoot>
                          </table>
                        </div>
                      : ''}
                      {/* <div>
                        <div className="mt-5  border  p-2 overflow-x-auto scrollbar-none flex items-center">
                          
                          <div className="whitespace-nowrap  border-black mr-6 font-semibold">
                            [{'Total Berat : '}
                            <span className="text-red-600">
                             
                              { parseFloat(totalBeratBarangTerkini) + parseFloat(totalBerat) }
                            </span>{' '}
                            gram ] &nbsp; &nbsp; &nbsp;
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layouts>
    );
  }
};

export default LaporanCashflow;
