import { IconRefresh, IconSearch, IconX } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';
import { CurrencyInput } from '../../utils/CurrencyInput';
import { DinamicTitle } from '../../utils/DinamicTitle';
import CetakStrokePembelian from './CetakStrokePembelian';
import '../../printStrokePembelian.css';
import LayoutsKasir from '../../layouts/LayoutsKasir';
import OpenSearchTrxJTrxB from './DetailScanTrxKasir/OpenSearchTrxJTrxB';
import ConfirmationPembelian from './ConfirmationPembelian';

export default function Pembelian() {
  const [kategori, setKategori] = useState([]);
  const [loading, setLoading] = useState(false);
  const [harga_beli, setHargaBeli] = useState(0);
  const [selectImage, setSelectImage] = useState([]);
  const [formData, setFormData] = useState({
    jenis_kategori_id: '',
    kondisi_barang: '',
    berat: '',
    karat: '',
    surat: '',
    keterangan: '',
  });
  const [gambar, setGambar] = useState('');
  // console.log(formData.karat)

  const [dataPrintPembelian, setDataPrintPembelian] = useState([]);
  // console.log('dataPrintPembelian::: ', dataPrintPembelian);

  const [scanKode, setScanKode] = useState('');
  const [dataScanKode, setDataScanKode] = useState([]);
  // console.log('dataScanKode::: ', dataScanKode);
  const [msg, setMsg] = useState('');

  const { jenis_kategori_id, kondisi_barang, karat, berat, surat, keterangan } = formData;

  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (formData.berat && !regex.test(formData.berat)) {
      setFormData({
        berat: formData.berat.slice(0, -1),
      });
    }
  }, [formData.berat]);

  function handleOnChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, [navigate]);

  function resetForm() {
    setFormData({
      jenis_kategori_id: '',
      kondisi_barang: '',
      berat: '',
      karat: '',
      surat: '',
      keterangan: '',
    });
  }

  async function handleSubmitPembelian(e) {
    e.preventDefault();
    const dataPembelian = {
      jenis_kategori_id,
      kondisi_barang,
      karat,
      berat,
      surat,
      harga_beli,
      keterangan,
      gambar,
    };
    // console.log(dataPembelian);
    setLoading(true);
    try {
      setLoading(true);
      const response = await http.post(`pembelian`, dataPembelian, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      setDataPrintPembelian(response.data.data);
      resetForm();
      setLoading(false);
      openModal();
      toast.success(response.data.message, {
        autoClose: 1000,
      });
      return response.data.data;
    } catch (err) {
      if (err.code === 'ERR_NETWORK') {
        toast.warning('Periksa Jaringan Internet Anda!');
      } else if (err.response.status === 422) {
        toast.warning('Silahkan Lengkapi data yang dimasukkan', {
          autoClose: 1000,
        });
      } else if (err.response.status === 401) {
        navigate('/login');
      } else if (err.response.status === 500) {
        toast.warning('Terjadi Kesalahan di Server!!');
      }
    }
    setLoading(false);
  }
  DinamicTitle('Kasir Pembelian || Komaladewi');
  const [loadingGetDetailScan, setLoadingGetDetailScan] = useState(false);
  const [statusKode, setStatusKode] = useState('');

  async function handleScanSurat() {
    setLoadingGetDetailScan(true);
    await http
      .get(`penjualan/scan/${scanKode}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setDataScanKode(res.data.data);
        setMsg(res.data.message);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setMsg(err.response.data.message);
          setStatusKode(err.response.status);
        }
      });
    setLoadingGetDetailScan(false);
  }

  const [jenisKategori, setJenisKategori] = useState([]);
  const [req, setReq] = useState({
    KategoriName: '',
    jenis_kategori_id: '',
  });

  async function SelectJenisKategori(e) {
    let name = e.target.name;
    let value = e.target.value;
    req[name] = value;
    let idData = value;
    await http
      .get(`/jenis/kategori/${idData}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);
        return res.data.data;
      })
      .then((resId) => {
        setReq(resId);
      })
      .catch((err) => {
        console.log('error jenis kategori', err);
      });
  }

  useEffect(() => {
    if (scanKode === '' && scanKode.length < 10) {
      console.log('Silahkan Masukkan Kode untuk mencari barang');
    } else {
      handleScanSurat();
    }
  }, [scanKode, navigate, statusKode]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    if (selectImage.length < 1) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', function () {
        setGambar(reader1.result.split(',')[1]);
      });
      reader1.readAsDataURL(selectedFiles[0]);
    }
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    // setSelectImage((previousImages) => previousImages.concat(imagesArray));
    setSelectImage(imagesArray);
    // FOR BUG IN CHROME
    event.target.value = '';
  };

  let [isOpenModalSearch, setIsOpenModalSearch] = useState(false);

  function closeModalSearch() {
    setIsOpenModalSearch(false);
    setScanKode('');
  }

  function openModalSearch() {
    setIsOpenModalSearch(true);
  }
  const karatOption = [
    { karat: 16 },
    { karat: 17 },
    { karat: 18 },
    { karat: 19 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];

  let [isOpenConfPembelian, setIsOpenConfPembelian] = useState(false);

  function closeModalConfPembelian() {
    setIsOpenConfPembelian(false);
  }

  function openModalConfPembelian() {
    setIsOpenConfPembelian(true);
  }

  return (
    <LayoutsKasir>
      <div className="md:mx-5 md:mt-4 mt-6">
        <ConfirmationPembelian
          isOpenConfPembelian={isOpenConfPembelian}
          closeModalConfPembelian={closeModalConfPembelian}
          handleSubmitPembelian={handleSubmitPembelian}
          loading={loading}
          formData={formData}
          harga_beli={harga_beli}
          selectImage={selectImage}
        />
        <OpenSearchTrxJTrxB
          setScanKode={setScanKode}
          isOpenModalSearch={isOpenModalSearch}
          closeModalSearch={closeModalSearch}
          scanKode={scanKode}
          dataScanKode={dataScanKode}
          loadingGetDetailScan={loadingGetDetailScan}
          statusKode={statusKode}
          msg={msg}
          numberWithCommas={numberWithCommas}
        />
        <CetakStrokePembelian dataPrintPembelian={dataPrintPembelian} isOpen={isOpen} closeModal={closeModal} />
        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
          </div>
        ) : (
          ''
        )}
        <div className="bg-white w-full rounded-md shadow-lg -mt-4">
          <div className="bg-green-500/10 flex items-center justify-between px-4 py-2 border-b">
            <div>
              <h1 className="text-xl font-semibold">Transaksi Pembelian</h1>
            </div>
            <div className="flex gap-2">
              <button onClick={openModalSearch} className="p-2 bg-green-500 text-white rounded-md">
                <IconSearch />
              </button>
              <button onClick={() => window.location.reload()} className="p-2 bg-slate-500 text-white rounded-md">
                <IconRefresh />
              </button>
            </div>
          </div>
          <div className="px-4 py-2">
            <form ref={formRef}>
              <div className="grid md:grid-cols-2 md:gap-2">
                <div>
                  <div className="w-full mb-2">
                    <label htmlFor="kategori" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Kategori
                    </label>
                    <select
                      // value={formData.jenis_kategori_id}
                      onChange={SelectJenisKategori}
                      required
                      // name='jenis_kategori_id'
                      className="select select-bordered w-full bg-white"
                    >
                      <option value="selected">Pilih Kategori</option>
                      {kategori.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nama_kategori}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full mb-2">
                    <label htmlFor="kategori" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Jenis Kategori
                    </label>
                    <select
                      value={formData.jenis_kategori_id}
                      onChange={handleOnChange}
                      name="jenis_kategori_id"
                      className="select select-bordered w-full bg-white"
                      required
                    >
                      <option value="selected">Pilih Jenis Kategori</option>
                      {jenisKategori.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nama_jenis}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-2">
                    <label htmlFor="kategori" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Kondisi Barang
                    </label>
                    <select
                      value={formData.kondisi_barang}
                      onChange={handleOnChange}
                      name="kondisi_barang"
                      required
                      className="select select-bordered w-full"
                    >
                      <option value="">Pilih Kondisi Barang</option>
                      <option value="Service">Service</option>
                      <option value="Ready Jual">Ready Jual</option>
                      <option value="Rongsok">Ronsok</option>
                    </select>
                  </div>
                  <div className="w-full">
                    <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Berat / gram
                    </label>
                    <input
                      placeholder='Berat / gram'
                      type="number"
                      id="berat"
                      name="berat"
                      required
                      value={formData.berat}
                      onChange={handleOnChange}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full mb-2">
                    <label htmlFor="karat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Karat
                    </label>
                    <select
                      value={karat}
                      id={'karat'}
                      name="karat"
                      onChange={handleOnChange}
                      className="select select-bordered w-full"
                    >
                      <option value="">Pilih Karat</option>
                      {karatOption.map((item, i) => (
                        <option key={i} value={item.karat}>
                          {item.karat}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="kategori" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Surat
                    </label>
                    <select
                      onChange={handleOnChange}
                      value={formData.surat}
                      name="surat"
                      className="select select-bordered w-full"
                    >
                      <option value="">Pilihan</option>
                      <option value="1">Ya</option>
                      <option value="0">Tidak</option>
                    </select>
                  </div>
                  <div className="mb-1">
                    <label
                      htmlFor="harga_beli"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Total Harga
                    </label>
                    <CurrencyInput
                      value={harga_beli}
                      onChange={(value) => setHargaBeli(() => value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Keterangan
                    </label>
                    <textarea
                      id="message"
                      value={formData.keterangan}
                      onChange={handleOnChange}
                      rows={4}
                      name="keterangan"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Keterangan dari transaksi pembelian"
                      // defaultValue={formData.keterangan}
                    />
                  </div>
                  <div className="mb-3">
                    <div>
                      <div className={`mt-2 flex gap-4 p-2  ${selectImage.length === 0 ? 'hidden' : ''}`}>
                        {selectImage.map((image, index) => (
                          <div key={index}>
                            <button
                              onClick={() => setSelectImage(selectImage.filter((e) => e !== image))}
                              className="absolute bg-white/50 rounded-full p-1"
                            >
                              <IconX width={20} />
                            </button>
                            <img width={90} src={image} key={index} alt="" srcSet="" />
                          </div>
                        ))}
                      </div>
                      <input
                        type="file"
                        required
                        onChange={handleImageChange}
                        className="
                    file:bg-blue-500 file:rounded-md file:border-none  w-full file:p-2 file:text-white file:cursor-pointer
                      bg-green-500/20 rounded-md  p-2 font-semibold
                "
                        accept="image/png , image/jpeg, image/webp"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  onClick={openModalConfPembelian}
                  className="text-white w-full md:w-48 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Simpan Pembelian
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LayoutsKasir>
  );
}
